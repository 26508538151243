export function intlNumberFormat(lang, num) {
    if (!num || !lang) {
        return false
    }
    if (Number.isNaN(Number(num))) {
        return num
    }
    return new Intl.NumberFormat(lang, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
}

export function intlNumberCurrencyFormat(lang, num) {
    if (!num || !lang) {
        return false
    }
    if (Number.isNaN(Number(num))) {
        return num
    }
    return `${new Intl.NumberFormat(lang, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)} €`
}