import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

import { Swiper, SwiperSlide } from "swiper/react";
import LocalizedLink from "../components/localizedLink";
import LoadingAnimation from "../components/loadingAnimation";
import { LocaleContext } from "../components/layout";
import useTranslations from "../hooks/useTranslations";

import { intlNumberFormat, intlNumberCurrencyFormat } from "../utils/format";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Pagination, Navigation, Thumbs } from "swiper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// markup
const BoatView = (boat) => {
  const { locale } = React.useContext(LocaleContext);
  const { boatPage, loadingAnimation, separator } = useTranslations();
  const b = boat.boat;
  const data = useStaticQuery(graphql`
    {
      boatImages: allFile(
        filter: { sourceInstanceName: { eq: "boatImages" } }
      ) {
        nodes {
          id
          base
          childImageSharp {
            gatsbyImageData(width: 640, aspectRatio: 1.49)
          }
        }
      }
      boatImagesThumbnail: allFile(
        filter: { sourceInstanceName: { eq: "boatImages" } }
      ) {
        nodes {
          id
          base
          childImageSharp {
            gatsbyImageData(width: 200, aspectRatio: 1.49)
          }
        }
      }
    }
  `);
  const widgetView = b.uebernachtung ? "calendar" : "slots";
  const prices = [
    {
      name: boatPage.prices.pricePerHour,
      duration: boatPage.prices.pricePerHourDuration,
      amount: b.preisStunde,
    },
    {
      name: boatPage.prices.fuel,
      duration: b.vollgetanktBeiAbgabe
        ? boatPage.prices.fullTankOnReturn
        : boatPage.prices.fuelDuration,
      amount: b.vollgetanktBeiAbgabe ? "" : b.kraftstoffProH,
    },
    {
      name: boatPage.prices.deposit,
      duration: boatPage.prices.depositDuration,
      amount: b.kaution,
      isHighlightedRed: true,
    },
    {
      singleLine:
        locale === "de"
          ? "Bezahlung: 50% Anzahlung, Restzahlung nur vor Ort"
          : "Payment: 50% upfront, rest on site",
      isHighlightedRed: true,
    },
    {
      name: locale === "de" ? "Angebote" : "Offers",
      highlighted: true,
    },
    {
      name: boatPage.prices.standard,
      duration: boatPage.prices.standardDuration,
      amount: b.preisStandard,
    },
    {
      name: boatPage.prices.hours3,
      amount: b.preis3Stunden,
    },
    {
      name: boatPage.prices.fullDay,
      duration: boatPage.prices.fullDayDuration,
      amount: b.preisGanztags,
    },
    {
      name: boatPage.prices.afterWork,
      duration: boatPage.prices.afterWorkDuration,
      amount: b.preisAfterWork,
    },
    {
      name: boatPage.prices.partyToMidnight,
      duration: boatPage.prices.partyToMidnightDuration,
      amount: b.preisPartyToMidnight,
    },
    {
      name: boatPage.prices.days3,
      amount: b.preis3Tage,
    },
    {
      name: boatPage.prices.days4,
      amount: b.preis4Tage,
    },
    {
      name: boatPage.prices.days5,
      amount: b.preis5Tage,
    },
    {
      name: boatPage.prices.days6,
      amount: b.preis6Tage,
    },
    {
      name: boatPage.prices.days7,
      amount: b.preis7Tage,
    },
    {
      name: locale === "de" ? "Rabatt" : "Discount",
      highlighted: true,
    },
    {
      name: boatPage.prices.discount3,
      duration: "9:00 - 18:00",
      amount: "20%",
    },
    {
      name:
        locale === "de" ? "Party durch die Nacht" : "Party through the night",
      highlighted: true,
    },
    b.skipper
      ? {
          singleLine:
            locale === "de"
              ? "Ihr könnt vom 01. Juni - 31. August auch über 22 Uhr hinaus buchen, mehr dazu weiter unten."
              : "You can book from June 1st - August 31st also after 10pm, more about that further down.",
        }
      : null,
  ];
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // create slider images array (images must be named as follow: slugDe-X.jpg -> eventboot-12-pers-begehbares-dach-1.jpg)
  const sliderImagesArray = [`${b.slugDe}.jpg`];
  if (b.sliderImagesQuantity !== null) {
    var i = 0;
    do {
      i += 1;
      sliderImagesArray.push(`${b.slugDe}-${i}.jpg`);
    } while (i < b.sliderImagesQuantity);
  }

  const equipmentFeaturesArray =
    locale === "de" ? b.ausstattungDe?.split(";") : b.ausstattungEn?.split(";");

  const boatName = locale === "de" ? b.nameDe : b.nameEn;
  const boatDescriptionTeaser =
    locale === "de" ? b.beschreibungTeaserDe : b.beschreibungTeaserEn;
  const boatDescriptionRest =
    locale === "de" ? b.beschreibungRestDe : b.beschreibungRestEn;
  const boatFuelType = locale === "de" ? b.fuelTypeDe : b.fuelTypeEn;
  const boatLenght =
    locale === "de"
      ? intlNumberFormat("de-DE", b.laenge)
      : intlNumberFormat("en-UK", b.laenge);
  const boatWidth =
    locale === "de"
      ? intlNumberFormat("de-DE", b.breite)
      : intlNumberFormat("en-UK", b.breite);

  return (
    <>
      <Helmet>
        <title>{`${boatName} ${separator} ${
          b.ps ? `${b.ps} ${boatPage.hp} ${separator}` : ``
        } ${boatPage.metaTitle}`}</title>
      </Helmet>
      <>
        <div className="grid items-start grid-cols-1 gap-8 p-4 mx-auto md:grid-cols-2 md:p-6 max-w-7xl md:gap-12 lg:gap-32">
          <div
            className={classNames(
              !b.uebernachtung &&
                "stickyWidgetScreen:sticky stickyWidgetScreen:top-4",
              "z-10 w-full mx-auto max-w-prose md:order-2"
            )}
          >
            <div className="flex mb-10 space-x-3 text-sm text-gray-400">
              <LocalizedLink to="/boote">{boatPage.boats}</LocalizedLink>
              <svg
                width={16}
                height={20}
                viewBox="0 0 16 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-4 h-5 text-gray-300"
              >
                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
              </svg>
              <span>{boatName}</span>
            </div>

            <div className="flex justify-between mb-4 space-x-3 lg:mb-6">
              <h1 className="text-4xl font-thin">{boatName}</h1>
              {b.abPreis > 0 && (
                <span className="self-end text-base text-gray-400">{`${boatPage.from} ${b.abPreis} €`}</span>
              )}
            </div>

            <div className="mb-10">
              <ul className="flex flex-wrap gap-2 text-xs text-white">
                {b.anzahlPersonen && (
                  <li className="px-2 py-1 bg-black rounded-full">
                    {`${boatPage.upTo} ${b.anzahlPersonen} ${boatPage.persons}`}
                  </li>
                )}
                {b.ps > 0 && (
                  <li className="px-2 py-1 bg-black rounded-full">
                    {`${b.ps} ${boatPage.hp}`}
                  </li>
                )}
                <li className="px-2 py-1 bg-black rounded-full">
                  {b.fuehrerschein
                    ? boatPage.licenseRequired
                    : boatPage.licenseWithout}
                </li>
                {b.uebernachtung && (
                  <li className="px-2 py-1 bg-black rounded-full">
                    {boatPage.overnight}
                  </li>
                )}
              </ul>
            </div>

            <div className="mb-10">
              {boatDescriptionTeaser && boatDescriptionRest && (
                <input
                  type="checkbox"
                  className="read-more-state"
                  id="boat-description"
                />
              )}

              <div>
                {boatDescriptionTeaser && (
                  <ReactMarkdown>{boatDescriptionTeaser}</ReactMarkdown>
                )}
                {boatDescriptionRest && (
                  <ReactMarkdown>{boatDescriptionRest}</ReactMarkdown>
                )}
              </div>

              {boatDescriptionTeaser && boatDescriptionRest && (
                <label htmlFor="boat-description" className="read-more-trigger">
                  <span
                    className={
                      locale === "de"
                        ? "read-more-trigger--de"
                        : "read-more-trigger--en"
                    }
                    type="text"
                    value="read more"
                  ></span>
                </label>
              )}
            </div>

            {b.rwId && !b.bookViaPhone && (
              <div className="grid w-full place-items-center min-h-[229px]">
                <div className="col-start-1 row-start-1 text-xs opacity-50">
                  <LoadingAnimation className="w-24 h-24 mx-auto" />
                  <div className="mt-1">{loadingAnimation.text1}</div>
                </div>
                <div className="w-full col-start-1 row-start-1 duration-700 rw-widget-delayed">
                  <rentware-article-booking
                    article-id={b.rwId}
                    view={widgetView}
                  ></rentware-article-booking>
                </div>
              </div>
            )}
            {b.bookViaPhone && (
              <div className="grid w-full p-4 border border-gray-300 rounded-lg shadow-xl md:p-6">
                <div className="mb-2 text-2xl font-thin">
                  {boatPage.booking}
                </div>
                <div className="mb-1 text-sm">
                  {boatPage.our} {boatName} {boatPage.personalContact}
                </div>
                <a
                  href="tel:+493034625303"
                  className="w-full px-3 py-2 mt-6 font-bold text-center text-white rounded-lg bg-als-red"
                  title="030 – 346 253 03"
                >
                  <span className="block sm:hidden">{boatPage.callUs}</span>
                  <span className="hidden sm:block">+4930 – 346 253 03</span>
                </a>
              </div>
            )}
            {b.uebernachtungAufAnfrage && (
              <div className="mt-10">
                <div className="text-center sm:text-left">
                  {boatPage.multiDayBooking}:
                </div>
                <a
                  href="tel:+493034625303"
                  className="block py-3 mb-4 font-bold"
                  title="030 – 346 253 03"
                >
                  <span className="block text-center underline sm:hidden">
                    {boatPage.callUs}
                  </span>
                  <span className="hidden sm:inline max-w-max">
                    +4930 – 346 253 03
                  </span>
                </a>
              </div>
            )}
          </div>

          <div className="w-full max-w-[640px] mx-auto">
            <div className="space-y-2">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "rgb(37 99 235",
                }}
                pagination={{
                  type: "progressbar",
                }}
                spaceBetween={10}
                navigation={true}
                grabCursor={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Pagination, Navigation, Thumbs]}
                className="mySwiper2"
              >
                {sliderImagesArray?.map((sliderImage) => {
                  const index = data.boatImages.nodes.findIndex(
                    (boatImage) => boatImage.base === sliderImage
                  );
                  const image = getImage(data.boatImages.nodes[index]);
                  if (image) {
                    return (
                      <SwiperSlide key={sliderImage}>
                        <GatsbyImage image={image} alt="" />
                      </SwiperSlide>
                    );
                  }
                  return null;
                })}
              </Swiper>
              {sliderImagesArray.length > 1 && (
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {sliderImagesArray?.map((sliderImage) => {
                    const index = data.boatImagesThumbnail.nodes.findIndex(
                      (boatImage) => boatImage.base === sliderImage
                    );
                    const image = getImage(
                      data.boatImagesThumbnail.nodes[index]
                    );
                    if (image) {
                      return (
                        <SwiperSlide key={sliderImage}>
                          <GatsbyImage image={image} alt="" />
                        </SwiperSlide>
                      );
                    }
                    return null;
                  })}
                </Swiper>
              )}
            </div>

            <div className="my-10">
              <h3 className="text-sm font-medium text-gray-900">
                {boatPage.prices.title}
              </h3>
              <dl className="mt-4 -mx-2 text-sm sm:-mx-4 price-table">
                {prices.map((price) => {
                  if (price === null) return null;
                  if (price.singleLine) {
                    return (
                      <div
                        key={price.name}
                        className={classNames(
                          price.highlighted
                            ? "!bg-black"
                            : price.isHighlightedRed
                            ? "!bg-als-red"
                            : "bg-white",
                          "font-medium px-2 py-3 sm:px-4"
                        )}
                      >
                        <span
                          className={classNames(
                            price.highlighted || price.isHighlightedRed
                              ? "text-white"
                              : "text-gray-500",
                            "text-sm max-w-prose"
                          )}
                        >
                          {price.singleLine}
                        </span>
                      </div>
                    );
                  }

                  if (price.amount === null) return null;

                  const amount =
                    locale === "de"
                      ? intlNumberCurrencyFormat("de-DE", price.amount)
                      : intlNumberCurrencyFormat("en-UK", price.amount);
                  return (
                    <div
                      key={price.name + price.duration + price.amount}
                      className={classNames(
                        price.highlighted
                          ? "!bg-black"
                          : price.isHighlightedRed
                          ? "!bg-als-red"
                          : "bg-white",
                        "font-medium px-2 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4"
                      )}
                    >
                      <dt
                        className={classNames(
                          price.highlighted || price.isHighlightedRed
                            ? "text-white"
                            : "text-gray-500",
                          "flex gap-2 justify-between text-sm sm:col-span-2"
                        )}
                      >
                        <span>{price.name}</span>
                        <span className="text-right">{price.duration}</span>
                      </dt>
                      <dd
                        className={classNames(
                          price.highlighted || price.isHighlightedRed
                            ? "text-white"
                            : "text-gray-900",
                          "mt-1 text-sm text-right sm:mt-0"
                        )}
                      >
                        {amount}
                      </dd>
                    </div>
                  );
                })}
              </dl>
              {b.vollgetanktBeiAbgabe && (
                <div className="my-2 text-xs text-gray-500">
                  {boatPage.prices.fullTankOnReturnDescription}
                </div>
              )}
            </div>

            <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
              {equipmentFeaturesArray && (
                <div className="mb-10">
                  <h3 className="text-sm font-medium text-gray-900">
                    {boatPage.equipmentFeatures}
                  </h3>

                  <div className="mt-4">
                    <ul className="pl-4 space-y-2 text-sm list-disc">
                      {equipmentFeaturesArray.map((equipmentFeature) => (
                        <li key={equipmentFeature} className="text-gray-400">
                          <span className="text-gray-600">
                            {equipmentFeature}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              {(b.skipper ||
                b.kuehlbox ||
                b.gasgrill ||
                b.trockenWc ||
                b.wasserdichtesFass ||
                b.wasserdichterBeutel) && (
                <div className="mb-10">
                  <h3 className="text-sm font-medium text-gray-900">
                    {boatPage.optionalExtras}
                  </h3>

                  <div className="mt-4">
                    <ul className="pl-4 space-y-2 text-sm list-disc">
                      {b.skipper && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.skipper}: ${b.skipper} € ${boatPage.prices.perHour}`}</span>
                        </li>
                      )}
                      {b.kuehlbox && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.coolerBox}: ${b.kuehlbox} € ${boatPage.prices.flatRate}`}</span>
                        </li>
                      )}
                      {b.gasgrill && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`Gasgrill: ${b.gasgrill} € ${boatPage.prices.flatRate}`}</span>
                        </li>
                      )}
                      {b.trockenWc && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.dryWc}: ${b.trockenWc} € ${boatPage.dryWcInclBags}`}</span>
                        </li>
                      )}
                      {b.wasserdichtesFass && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.waterproofsBarrel}: ${b.wasserdichtesFass} € ${boatPage.prices.perDay}`}</span>
                        </li>
                      )}
                      {b.wasserdichterBeutel && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.dryBag}: ${b.wasserdichterBeutel} € ${boatPage.prices.perDay}`}</span>
                        </li>
                      )}
                    </ul>
                    <div className="mt-4 text-xs text-gray-500">
                      {boatPage.optionalExtrasExplaination}
                    </div>
                  </div>
                </div>
              )}

              {b.skipper && (
                <div className="mb-10">
                  <h3 className="text-sm font-medium text-gray-900">
                    {locale === "de" ? "Fahrten über 22 Uhr hinaus" : "Skipper"}
                  </h3>

                  <div className="mt-4 text-sm">
                    <ul className="pl-4 space-y-2 list-disc">
                      <li className="text-gray-400">
                        <span className="text-gray-600">
                          {locale === "de"
                            ? "Juni bis 31. August"
                            : "June to 31. August"}
                        </span>
                      </li>
                      <li className="text-gray-400">
                        <span className="text-gray-600">
                          {locale === "de"
                            ? "nur mit Skipper"
                            : "only with skipper"}
                        </span>
                      </li>
                    </ul>
                    <p className="mt-4 text-gray-600">
                      Bucht dafür bis 22 Uhr und dann im 2. Buchungsschritt die
                      gewünschte Anzahl der Verlängerungsstunden dazu. Der
                      Skipper wird automatisch dem Warenkorb hinzugefügt.
                      Beachtet dabei: Der Skipper kommt von Beginn an aufs Boot,
                      der er nicht später dazu steigen kann. Ihr bucht den
                      Skipper also für die gesamte Fahrt, wenn ihr länger als
                      bis 22 Uhr unterwegs sein wollt.
                    </p>
                  </div>
                </div>
              )}

              {(b.laenge ||
                b.breite ||
                b.tiefgang ||
                b.tank ||
                b.gewichtKg) && (
                <div className="mb-10">
                  <h3 className="text-sm font-medium text-gray-900">
                    {boatPage.keyData.title}
                  </h3>

                  <div className="mt-4">
                    <ul className="pl-4 space-y-2 text-sm list-disc">
                      {boatLenght && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.keyData.length}: ${boatLenght}m`}</span>
                        </li>
                      )}
                      {boatWidth && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.keyData.width}: ${boatWidth}m`}</span>
                        </li>
                      )}
                      {b.tiefgang && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.keyData.draught}: ${b.tiefgang}cm`}</span>
                        </li>
                      )}
                      {b.gewichtKg && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${boatPage.keyData.weight}: ${b.gewichtKg}kg`}</span>
                        </li>
                      )}
                      {b.tank && (
                        <li className="text-gray-400">
                          <span className="text-gray-600">{`${
                            boatPage.keyData.fuelTank
                          }: ${b.tank}L ${boatFuelType ?? ""}`}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default BoatView;
